// @flow
import React from 'react';
import loadable from '@loadable/component';
import Box from '@material-ui/core/Box';
import { blocks } from './const';
import NavigationBlock from '../Merchant/shared/NavigationBlock';

const CompanyTeam = loadable(() => import(/* webpackPreload: true */ './Team'));
const InfoTabs = loadable(() => import(/* webpackPreload: true */ './Info'));
const ArticlesTop = loadable(() =>
  import(/* webpackPreload: true */ './ArticlesTop')
);
const ArticlesBottom = loadable(() =>
  import(/* webpackPreload: true */ './ArticlesBottom')
);

// const Example = loadable(() => import(/* webpackPreload: true */ './Example'));
// const Flow = loadable(() => import(/* webpackPreload: true */ './Flow'));
// const Documents = loadable(() =>
//   import(/* webpackPreload: true */ './Documents')
// );

const Navigation = () => {
  return (
    <Box bgcolor="#fafafa">
      <NavigationBlock pillColor="secondary" blocks={blocks}>
        <CompanyTeam />
        <InfoTabs />
        <ArticlesTop />
        <ArticlesBottom />
      </NavigationBlock>
    </Box>
  );
};

export default Navigation;
