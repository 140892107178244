/* eslint-disable no-useless-computed-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow
import { Box } from '@material-ui/core';
import React, { useRef } from 'react';
import TabsWrapper from '../../shared/Tabs/TabsWrapper';
import Tab from '../../shared/Tabs/Tab';
import { navigationStyles } from './styles';

const Navigation = ({
  blocks,
  children,
  pillColor = 'primary',
}: {
  blocks: Array<Object>,
  children: Object,
  pillColor?: string,
}) => {
  const navRef = useRef();
  const classes = navigationStyles({});

  return (
    <Box position="relative" className={classes.wrapper}>
      <div className={classes.header} ref={navRef}>
        <TabsWrapper>
          {blocks.map((block) => (
            <Tab
              pillColor={pillColor}
              id={`tab-${block.id}`}
              to={block.id}
              key={block.id}
              ref={navRef}
            >
              {block.title}
            </Tab>
          ))}
        </TabsWrapper>
      </div>
      {children}
    </Box>
  );
};

Navigation.defaultProps = {
  pillColor: `primary`,
};

export default Navigation;
