// @flow
import React from 'react';

import Layout from '../../components/Layout/Layout';
import CompanyIntro from '../../components/Company/Intro';
import CompanyPromoTop from '../../components/Company/PromoTop';
import PromoBottom from '../../components/Company/PromoBottom';
import Navigation from '../../components/Company/Navigation';

import styles from '../../styles/pages/company.module.scss';
import { SITE_METADATA } from '../../constants';

const CompanyIndexPage = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.company.title,
        description: SITE_METADATA.company.description,
      }}
    >
      <div className={styles.heroWrapper}>
        <h1>会社プロフィール</h1>
      </div>
      <CompanyIntro />
      <CompanyPromoTop />
      <Navigation />
      <PromoBottom />
    </Layout>
  );
};

// $FlowFixMe
export default CompanyIndexPage;
