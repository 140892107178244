/* eslint-disable import/prefer-default-export */
export const blocks = [
  {
    id: 'team',
    title: 'マネジメントチーム',
  },
  {
    id: 'overview',
    title: '会社概要',
  },
  {
    id: 'articles-top',
    title: 'Paidyプレス',
  },
  {
    id: 'articles-bottom',
    title: 'IR/投資家情報',
  },
];
