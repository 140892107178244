// @flow
import React from 'react';
import styles from './Intro.module.scss';

export default () => (
  <section className={styles.introWrapper}>
    <div className={styles.intro}>
      <div>
        <h2>
          お買いものに
          <br />
          「めんどくさい」はいらない。
        </h2>
        <p>
          人を信頼するのは簡単ではない。
          <br />
          だからお買いものには多くの「めんどくさい」がつきまとう。
          <br />
          <br />
          人を信頼してみよう。
          <br />
          Paidyはそこから生まれた。
          <br />
          <br />
          「めんどくさい」をなくす力は、人を信頼する力でもある。
        </p>
      </div>
    </div>
  </section>
);
