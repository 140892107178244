// @flow
import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';
import cx from 'classnames';
import styles from './Promo.module.scss';

export default () => (
  <section className={cx(styles.register, styles.bottomBg)}>
    <div className={styles.registerWrapper}>
      <div className={styles.title}>
        Paidyのロゴや
        <br />
        会社案内をダウンロード
      </div>
      <Button
        variant="text"
        color="primary"
        component={Link}
        to="/media_center/press_kit/"
      >
        プレスキットはこちら
      </Button>
    </div>
  </section>
);
