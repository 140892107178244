import React from 'react';
import cx from 'classnames';
import { Link } from 'react-scroll';
import { tabStyles } from './styles';

const Tab = React.forwardRef(
  ({ full = false, id, to, children, pillColor = 'primary' }, ref) => {
    const classes = tabStyles({});

    const setActiveTab = () => {
      const targetElement = document.getElementById(id);
      const targetElementLeft = targetElement.offsetLeft;
      const targetElementWidth = targetElement.clientWidth;
      const vpw = document.documentElement
        ? document.documentElement.clientWidth - targetElementWidth
        : 0;
      const header = ref && ref.current;

      if (header) {
        const isTargetElementInWp =
          targetElementLeft > vpw + header.scrollLeft ||
          header.scrollLeft > targetElementLeft;

        if (isTargetElementInWp) {
          header.scrollLeft = document.getElementById(id)
            ? document.getElementById(id).offsetLeft
            : 0;
        }
      }
    };

    return (
      <>
        <li id={id}>
          <Link
            className={cx(classes.tab, {
              [classes.full]: full,
              [classes.primaryPill]: pillColor === 'primary',
              [classes.secondaryPill]: pillColor === 'secondary',
            })}
            activeClass={classes.active}
            to={to}
            spy
            smooth
            offset={-146}
            duration={500}
            onSetActive={setActiveTab}
          >
            {children}
          </Link>
        </li>
      </>
    );
  }
);

export default Tab;
