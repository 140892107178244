/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const tabsWrapperStyles = makeStyles((theme) => ({
  container: {
    // overflow: 'auto',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    padding: '32px',
    gap: '22px',
    listStyle: 'none',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
      justifyContent: 'left',
    },
  },
  full: {
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down('md')]: {
    wrapper: {
      gap: '64px',
    },
  },
}));

export const tabStyles = makeStyles((theme) => ({
  tab: {
    font: `400 14px/21px ${theme.typography.fontFamily}`,
    color: '#404042',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '60px',
    paddingBottom: '12px',
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'nowrap',
      marginRight: 'initial',
    },
    '& > a': {
      font: `400 14px/16px ${theme.typography.fontFamily}`,
      color: '#1c1c1c',
    },
  },
  primaryPill: {
    '&::before': {
      background: 'linear-gradient(135deg, #2196F3 0%, #823CD7 100%)',
    },
  },
  secondaryPill: {
    '&::before': {
      background: 'linear-gradient(59.24deg, #E5258C 9.27%, #863BD4 81.8%)',
    },
  },
  active: {
    fontWeight: 700,
    background:
      'linear-gradient(135deg, #E5258C 0%, #7D35D4 100%) left bottom no-repeat',
    backgroundSize: '100% 2px',
  },
  full: {
    marginRight: '0px',
  },
}));
