// @flow
import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';
import cx from 'classnames';
import styles from './Promo.module.scss';

export default () => (
  <section className={cx(styles.register, styles.topBg)}>
    <div className={styles.registerWrapper}>
      <div className={styles.title}>
        勝ちにこだわり、
        <br />
        大切なピースになる。
      </div>
      <Button
        variant="text"
        color="primary"
        component={Link}
        to="/media_center/work_life/recruitment/"
      >
        採用ページはこちら
      </Button>
    </div>
  </section>
);
