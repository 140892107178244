/* eslint-disable no-useless-computed-key */
/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import cross from '../../../images/cross.svg';
import MaterialsBg from '../../../images/materials.png';
import StartSpBg from '../../../images/start-sp.png';

export const heroStyles = makeStyles((theme) => ({
  container: {
    background: ({ backgroundPc }) =>
      `url(${backgroundPc}) no-repeat center center / cover`,
    height: ({ logoPaidy }) => (logoPaidy ? '340px' : '280px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (max-width: 1024px)': {
      background: ({ backgroundSp }) =>
        `url(${backgroundSp}) no-repeat center bottom / cover`,
      height: ({ subTitle, logoPaidy }) => {
        if (logoPaidy) {
          return 342;
        } else if (subTitle) {
          return 300;
        }

        return 214;
      },
      backgroundSize: 'cover',
    },
    '& > h1': {
      padding: '4px 24px',
      color: '#fff',
      background: '#1c1c1c',
      display: 'inline-block',
      borderRadius: 8,
      textAlign: 'center',
      fontFamily: '"Noto Sans JP"',
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '60px',

      '@media (max-width: 1024px)': {
        padding: 12,
        lineHeight: '42px',
        marginTop: ({ subTitle }) => (subTitle ? theme.spacing(3) : 0),
        font: ({ subTitle }) =>
          subTitle
            ? `700 20px/20px ${theme.typography.fontFamily} !important`
            : `700 24px/24px ${theme.typography.fontFamily} !important`,
      },
    },
    '& > p': {
      marginTop: '32px',
      font: `400 24px/34px ${theme.typography.fontFamily} !important`,
      '@media (max-width: 1024px)': {
        font: `400 18px/32px ${theme.typography.fontFamily} !important`,
      },
    },
  },
  paidyLogo: {
    width: 160,
    height: 45,
    marginBottom: 32,
  },
}));

export const imagePopupStyles = makeStyles(() => ({
  popup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popupContent: {
    position: 'relative',
    maxWidth: 1300,
    '&>img': {
      maxWidth: '100vw',
      maxHeight: 'calc(100vh - 100px)',
      borderRadius: 20,
      top: 50,
      bottom: 50,
      '@media (max-width: 1024px)': {
        maxWidth: 'calc(100vw - 40px)',
        maxHeight: 'calc(100vh - 80px)',
        top: 20,
        bottom: 10,
        left: 20,
        right: 20,
      },
    },
  },
  popupClose: {
    width: 18,
    height: 18,
    backgroundImage: `url(${cross})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    right: 22,
    top: -28,
    '@media (max-width: 1024px)': {
      top: -38,
    },
  },
}));

export const introStyles = makeStyles(() => ({
  container: {
    background: `#F0F0F0`,
    padding: '64px 0',
    '@media (max-width: 1024px)': {
      padding: '70px 0 50px',
    },
    '& > div': {
      height: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
  },
  title: {
    color: '#101825',
    textAlign: 'center',
    fontFamily: '"Noto Sans JP"',
    fontSize: '36px',
    fontWeight: 900,
    lineHeight: '50px',
    letterSpacing: '0.622px',
    marginBottom: '64px',
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '34px',
      marginBottom: '40px',
    },
  },
  subtitleWrapper: {
    textAlign: 'left',
    width: 'initial',
    '@media (max-width: 1024px)': {
      textAlign: 'left',
      width: '100%',
    },
  },
  subtitle: {
    color: '#101825',
    fontFamily: '"Noto Sans JP"',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.415px',
    marginBottom: '32px',

    '@media (max-width: 1024px)': {
      marginBottom: '18px',
      paddingLeft: '36px',
      display: 'inline-block',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '26px',
    },
    '@media (max-width: 767.98px)': {
      paddingLeft: 'initial',
    },
  },
  textWrapper: {
    color: '#101825',
    fontFamily: '"Noto Sans JP"',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    maxWidth: '80%',
    marginBottom: '64px',

    '@media (max-width: 1024px)': {
      marginBottom: '40px',
      maxWidth: '90%',
      width: '90%',
    },
    '@media (max-width: 767.98px)': {
      marginBottom: '40px',
      maxWidth: '100%',
      width: 'initial',
    },
  },
}));

export const merchantsStyles = makeStyles((theme) => ({
  logos: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '& a': {
      flex: 1,
      height: theme.spacing(8),
      padding: theme.spacing(1),
      marginTop: theme.spacing(8),
      width: '148px',
      display: 'block',
      boxSizing: 'content-box',
      '@media (max-width: 1024px)': {
        marginTop: 0,
        padding: 0,
      },
      '@media (max-width: 374.98px)': {
        width: '160px',
      },
      '& figure': {
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
      },
      '& img': {
        '@media (max-width: 374.98px)': {
          objectFit: 'contain',
        },
      },
    },
  },
  logoWrapper: {
    display: 'flex',
  },
  logo: {
    objectFit: 'contain',
  },
}));

export const navigationStyles = makeStyles((theme) => ({
  wrapper: {
    background: '#FAFAFA',
    '@media (max-width: 1024px)': {
      paddingTop: 'initial',
    },
  },
  header: {
    backgroundColor: 'white',
    position: 'sticky',
    // paddingTop: theme.spacing(3),
    top: '64px',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      // paddingTop: '20px',
      top: '55px',
    },
    ['@media (max-device-width: 1366px)']: {
      paddingTop: '20px',
      top: '55px',
    },
    '@media (max-width: 1024px)': {
      paddingTop: 0,
      top: '55px',
      overflow: 'auto',
      whiteSpace: 'nowrap',
    },
  },
}));

export const partnersStyles = makeStyles((theme) => ({
  logos: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '959px',
    justifyContent: 'flex-start',
    gap: 16,
    '& a': {
      flexBasis: '227px',
      height: '71px',
      padding: theme.spacing(1),
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& figure': {
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
      },
    },
    '@media (max-width: 1024px)': {
      width: '688px',
      '& a': {
        flexBasis: '160px',
        height: 50,
      },
    },
    '@media (max-width: 735px)': {
      width: '512px',
    },
    '@media (max-width: 543px)': {
      width: '336px',
    },
  },
  image: {
    // width: 'auto',
    maxWidth: '200px',
    maxHeight: '50px',
    '@media (max-width: 1024px)': {
      maxHeight: '35px',
      maxWidth: '135px',
    },
  },
  imageWrapper: {
    background: 'white',
  },
}));

export const startStyles = makeStyles(() => ({
  container: {
    background: `url(${MaterialsBg})`,
    backgroundSize: 'cover',
    padding: '48px 0',
    '@media (max-width: 1024px)': {
      background: `url(${StartSpBg})`,
      backgroundSize: 'cover',
      padding: '64px 12px',
      height: '100%',
    },
    '& > div': {
      height: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
  },
  title: {
    fontWeight: 900,
    color: '#101825',
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  description: {
    textAlign: 'center',
    margin: '64px 0',
    maxWidth: '80%',
    '@media (max-width: 1024px)': {
      margin: '48px 0',
      textAlign: 'left',
    },
    '@media (max-width: 767.98px)': {
      maxWidth: '100%',
    },
  },
  button: {
    color: 'white',
    font: '600 16px/24px normal',
    display: 'flex',
    width: '328px',
    padding: '16px',
    borderRadius: '28px',
    background: '#404042',
    '&:hover': {
      background: '#404042',
    },
  },
}));

export const caseStudiesStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '100px',
    '@media (max-width: 1024px)': {
      paddingBottom: '60px',
    },
  },
  container: {
    paddingTop: '100px',
    paddingBottom: '64px',
    '@media (max-width: 1024px)': {
      paddingTop: '60px',
      paddingBottom: '56px',
    },
  },
  title: {
    '@media (max-width: 1024px)': {
      fontSize: '28px',
      lineHeight: '40px',
    },
  },
}));

export const companyStyles = makeStyles(() => ({
  container: {
    padding: '88px 0',
    background: '#FAFAFA',
    '@media (max-width: 1024px)': {
      padding: '72px 0',
    },
  },
  title: {
    textAlign: 'center',
  },
  wrapper: {
    paddingTop: '64px',
    '@media (max-width: 1024px)': {
      paddingTop: '40px',
    },
  },
  subtitle: {
    color: '#101825',
    textAlign: 'center',
    fontFamily: '"Noto Sans JP"',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '50px',
    letterSpacing: '0.622px',
    '@media (max-width: 1024px)': {
      color: '#000',
      fontSize: '24px',
      lineHeight: '34px',
      paddingBottom: '39px',
    },
  },
}));
