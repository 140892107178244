// @flow
import React from 'react';
import cx from 'classnames';
import { Container } from '@material-ui/core';
import { tabsWrapperStyles } from './styles';

const TabsWrapper = ({
  full = false,
  children,
}: {
  full?: boolean,
  children: Object,
}) => {
  const classes = tabsWrapperStyles({});

  return (
    <Container maxWidth="md">
      <div className={classes.container}>
        <ul className={cx(classes.wrapper, { [classes.full]: full })}>
          {children}
        </ul>
      </div>
    </Container>
  );
};

TabsWrapper.defaultProps = {
  full: false,
};

export default TabsWrapper;
